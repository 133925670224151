@function rem($value) {
  @if unit($value) == 'px' {
    // Assume the 16px browser default is used.
    @return ($value / 16px) * 1rem;
  } @else {
    @error 'Expected px or em value, got `#{unit($value)}`';
  }
}

@mixin visuallyhidden {
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
}

$edge-distance: 10px;
$edge-distance-large: 15px;
$content-width: 40em;
$z-index: 5000;
$animation-duration: 0.25s;

@keyframes consent-backdrop-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes consent-enter {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes consent-enter--center {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) translateY(0);
  }
}
@keyframes consent-enter--middle {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

.consent-backdrop--modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index;
  background: rgba(0, 0, 0, 0.4);
  animation: consent-backdrop-enter $animation-duration ease 1;
}

.consent {
  position: fixed;
  min-width: 20em;
  max-width: 90%;
  max-width: calc(100% - #{$edge-distance * 2});
  // The title will 'add' some padding visually
  padding: 13px 15px 15px;
  border: 1px solid;
  border-radius: 5px;
  // prettier-ignore
  box-shadow:
    0 0 1px rgba(0, 0, 0, 0.06),
    0 1px 2px rgba(0, 0, 0, 0.06),
    0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07);
  font-size: rem(14px);
  animation: consent-enter $animation-duration ease 1;
  z-index: $z-index + 1;

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
}

/* ------------------- Position ------------------- */
.consent--pos-top {
  top: $edge-distance;
}
.consent--pos-bottom {
  bottom: $edge-distance;
}
.consent--pos-left {
  left: $edge-distance;
}
.consent--pos-right {
  right: $edge-distance;
}

// The percentage centering makes the natural width behave differently from how
// it is for the corner positions and it's less nice on smaller screens. Set
// a width in this case, using max-content where supported to still match the
// text for when it's short.
.consent--pos-center,
.consent--pos-middle {
  width: $content-width;
  width: max-content;
}
.consent--pos-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: consent-enter--center;
}
.consent--pos-middle {
  left: 50%;
  transform: translateX(-50%);
  animation-name: consent-enter--middle;
}

@media screen and (min-width: rem(600px)) {
  .consent--pos-top {
    top: $edge-distance-large;
  }
  .consent--pos-bottom {
    bottom: $edge-distance-large;
  }
  .consent--pos-left {
    left: $edge-distance-large;
  }
  .consent--pos-right {
    right: $edge-distance-large;
  }
  .consent {
    max-width: calc(100% - #{$edge-distance-large * 2});
  }
}

/* ------------------- Theme ------------------- */
@mixin light-theme {
  border-color: #e4e4e4;
  background-color: #fff;
  color: #333;
}
@mixin dark-theme {
  border-color: #333;
  background-color: #222;
  color: #fafafa;
}
.consent--theme-light,
.consent--theme-auto {
  @include light-theme;
}
.consent--theme-dark {
  @include dark-theme;
}
@media (prefers-color-scheme: dark) {
  .consent--theme-auto {
    @include dark-theme;
  }
}

/* ------------------- Content ------------------- */
.consent__content {
  max-width: $content-width;
}

.consent__title {
  font-size: rem(18px);
}
.consent__text {
  margin: 0 0 1em;
}

/* ------------------- Group checkboxes ------------------- */
.consent__groups {
  margin-bottom: 0.5em;

  legend {
    @include visuallyhidden;
  }
}

.consent__group {
  display: inline-block;
  margin: 0 1em 0.5em 0;

  input,
  span {
    vertical-align: middle;
  }
}

/* ------------------- Action buttons ------------------- */
.consent__actions {
  margin-left: -5px;
  margin-right: -5px;
}
.consent__actions--center {
  text-align: center;
}
.consent__actions--right {
  text-align: right;
}
.consent__actions--full {
  display: flex;
}

.consent__action {
  display: inline-block;
  margin: 10px 5px 0;
  padding: 6px 12px;
  border: 0;
  border-radius: 3px;
  font-weight: normal;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  text-align: center;
  text-shadow: none;
  text-transform: none;
  vertical-align: middle;
  transition: background-color 0.1s ease;
  white-space: nowrap;
}
.consent__action--full {
  flex: 1 1 50%;
}

@media screen and (max-width: rem(450px)) {
  .consent__actions--full {
    flex-wrap: wrap;
  }
  .consent__action--full {
    flex-basis: auto;
  }
}

.consent__action--primary {
  background-color: #1e6bc4;
  color: #fff;

  &:hover,
  &:focus {
    background-color: mix(#000, #1e6bc4, 20%);
  }
}

.consent__action--secondary {
  background-color: #eee;
  color: #333;

  &:hover,
  &:focus {
    background-color: mix(#000, #eee, 10%);
  }
}
.consent__action--secondary--dark {
  background-color: #555;
  color: #fff;

  &:hover,
  &:focus {
    background-color: mix(#fff, #555, 10%);
  }
}
